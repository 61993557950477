import "./createcountry.css";
import React, { useEffect, useState } from "react";
import useStatefulFields from "./hooks/setfild";
import axios from "axios";
import Edit from "./edit.js";

const CreateWine = () => {
    const [values, handleChange] = useStatefulFields();
    const [clist, setClist] = useState({});
    const [countrylist, setCountrylist] = useState({});
    const [typelist, settypelist] = useState({});
    const [toedit, setToEdit] = useState([]);
    const [idval, setIdval] = useState(1);
    const [portal, setportal] = useState(false);
    const [back, setBack] = useState({});
    useEffect(async () => {
        const country = await axios.get(`/countrylist`);
        console.log("country", country.data.data);
        const type = await axios.get(`/typelist`);
        console.log("type", type.data.data);
        setCountrylist(country.data.data);
        settypelist(type.data.data);

        const { data } = await axios.get(`/winelist`);
        setClist(data.data);
        console.log(data.data);

        if (data) {
            let le = data.data.length;

            setIdval(data.data[le - 1].id + 1);
        }
    }, []);
    useEffect(async () => {
        console.log("this back ", back);
        const { data } = await axios.post(`/typelistup`, { ...back });
        console.log("back from db after updated ", data);
    }, [back]);

    const clicked = async (e) => {
        e.preventDefault();
        console.log(values);
        const { data } = await axios.post(`/winelist`, {
            ...values,
            id: idval,
        });

        setClist([...clist, data.data[0]]);
        console.log("add test", data.data[0]);
        console.log("clist ", clist);
    };
    const editcountry = async (countryId) => {
        await setportal(false);
        console.log("country ID ", countryId);
        setToEdit(clist[countryId]);
        setportal(true);
    };
    const deletfood = async (typeId) => {
        await setportal(false);
        console.log("type ID ", typeId);
        const { data } = await axios.post(`/typelistdel`, { typeId });
        setToEdit(clist[typeId - 1]);
        // setportal(true);
    };

    return (
        <div>
            <form className="ccountry_form">
                <h3>Adding a new country</h3>
                <input
                    name="id"
                    placehold="ID"
                    type="number"
                    onChange={handleChange}
                    disabled
                    value={idval}
                />
                <input
                    name="wine_name"
                    placehold="wine name"
                    onChange={handleChange}
                />
                <input
                    name="country"
                    placehold="country"
                    onChange={handleChange}
                />
                <select name="country" onChange={handleChange}>
                    {countrylist.length > 0 &&
                        countrylist.map((x, idx) => {
                            return <option value={x.id}>{x.country}</option>;
                        })}
                </select>
                <select name="wine_type" onChange={handleChange}>
                    {typelist.length > 0 &&
                        typelist.map((x, idx) => {
                            return <option value={x.id}>{x.type_name}</option>;
                        })}
                </select>
                <input
                    name="wine_type"
                    placehold="wine type"
                    onChange={handleChange}
                />
                <input name="dis" placehold="dis" onChange={handleChange} />
                <input
                    name="uniq_taste"
                    placehold="uniq taste  "
                    onChange={handleChange}
                />
                <input
                    name="price"
                    placehold="price"
                    type="number"
                    onChange={handleChange}
                />
                <input
                    name="years"
                    placehold="years"
                    type="number"
                    onChange={handleChange}
                />
                <input
                    name="img"
                    type="url"
                    placehold="img URL "
                    onChange={handleChange}
                />
                <input
                    name="vol"
                    placehold="vol"
                    type="number"
                    onChange={handleChange}
                />
                <input
                    name="bio"
                    placehold="bio"
                    type="checkbox"
                    onChange={handleChange}
                />

                <h3>Password </h3>

                <input type="password" name="pass" onChange={handleChange} />

                <button onClick={(e) => clicked(e)}>Create</button>
            </form>

            <div className="edititem">
                {clist.length > 0 &&
                    clist.map((x, idx) => {
                        return (
                            <div className="basiclist" key={idx}>
                                <h3> ID : {x.id} </h3>
                                <h3> wine name : {x.wine_name} </h3>
                                <h3> country : {x.country} </h3>
                                <h3>dis : {x.dis}</h3>
                                <h3> uniq taste : {x.uniq_taste} </h3>
                                <h3> price : {x.price} </h3>
                                <h3> years : {x.years} </h3>
                                <h3> img : {x.img} </h3>
                                <img src={x.img} className="thumnail" />
                                <h3> vol : {x.vol} </h3>
                                <h3> bio : {x.bio} </h3>
                                <h3> created at : {x.created_at} </h3>
                                <button onClick={() => editcountry(idx)}>
                                    Edit
                                </button>
                                {/* <button onClick={() => deletfood(x.id)}>
                                    Delete
                                </button> */}
                            </div>
                        );
                    })}
            </div>
            {portal && <Edit obj={toedit} back={setBack} portal={setportal} />}
        </div>
    );
};

export default CreateWine;
