import React from "react";
// import av from "./avatar/av.webp";
// import av1 from "./avatar/001.webp";
// import av2 from "./avatar/002.webp";
// import av3 from "./avatar/003.webp";
// import av4 from "./avatar/004.webp";
// import av5 from "./avatar/005.webp";
// import av6 from "./avatar/006.webp";
const Avatarmain = (props) => {
    return <img src={props.avatar} alt="user avatar" className="avatarmain" />;
};

export default Avatarmain;
