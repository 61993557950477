import React from "react";
import whitewine from "./avatar/wwine.webp";
import redwine from "./avatar/redwine.png";
import rose from "./avatar/rose.png";
const FisrtQ = (props) => {
    return (
        props.steped === 1 && (
            <div className="comp">
                <h2>1. How do you normally drink your wine?</h2>
                {/* <img
                    src={redwine}
                    alt="Red Wine"
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "red" });
                        props.stepp(2);
                    }}
                />
                <img
                    src={whitewine}
                    alt="White Wine "
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "white" });
                        props.stepp(2);
                    }}
                />
                <img
                    src={rose}
                    alt="rose Wine "
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "rose" });
                        props.stepp(2);
                    }}
                /> */}
                <div className="row flexgrow colmob topmarginmob">
           
                    <div className="col box400px point rowmob">
                        <img
                            src="https://i.imgur.com/nYUmXnr.png"
                            onClick={() => {
                                props.pla({
                                    ...props.pala,
                                    snack: true,
                                    pure: true,
                                    laststep: 1,
                                });
                                props.stepp(10);
                            }}
                        />
                        <div className="emptydiv2 notmob"></div>
                        <div className="emptydiv2 notmob"></div>

                        <h2
                            onClick={() => {
                                props.pla({
                                    ...props.pala,
                                    snack: true,
                                    pure: true,
                                    laststep: 1,
                                });
                                props.stepp(10);
                            }}
                        >
                            pure
                        </h2>
                    </div>
                    <div className="col box400px point rowmob">
                        <img
                            src="https://i.imgur.com/OdHHMF1.png"
                            onClick={() => {
                                props.pla({
                                    ...props.pala,
                                    snack: false,
                                    pure: false,
                                    laststep: 1,
                                });
                                props.stepp(2);
                            }}
                        />
                        <div className="emptydiv2 notmob"></div>
                        <div className="emptydiv2 notmob"></div>

                        <h2
                            onClick={() => {
                                props.pla({
                                    ...props.pala,
                                    snack: false,
                                    pure: false,
                                    laststep: 1,
                                });
                                props.stepp(2);
                            }}
                        >
                            While Dining
                        </h2>
                    </div>
                    <div className="col box400px point rowmob">
                        <img
                            src="https://i.imgur.com/Ixiy1M7.png"
                            onClick={() => {
                                props.pla({
                                    ...props.pala,
                                    snack: true,
                                    pure: false,
                                    laststep: 1,
                                });
                                props.stepp(2);
                            }}
                        />
                        <div className="emptydiv2 notmob"></div>
                        <div className="emptydiv2 notmob"></div>

                        <h2
                            onClick={() => {
                                props.pla({
                                    ...props.pala,
                                    snack: true,
                                    pure: false,
                                    laststep: 1,
                                });
                                props.stepp(2);
                            }}
                        >
                            with a side dish
                        </h2>
                    </div>
                </div>
            </div>
        )
    );
};

export default FisrtQ;
