import "./App.css";
import "./appm.css";
import { BrowserRouter, Route, Link,HashRouter
 } from "react-router-dom";
import Avatar from "./avatar.js";
import Avatarmain from "./avatarmain.js";
import Colorr from "./color.js";
import Season from "./season.js";
import Taste from "./taste.js";
import Country from "./country.js";
import Final from "./final.js";
import Createcountry from "./createcountry.js";
import CreateFoodP from "./createfoodp.js";
import CreateWineType from "./createwinetype.js";
import CreateWine from "./createwine.js";
import FoodType from "./foodtype.js";
import FirstQ from "./firestq.js";
import MatchWine from "./matchedwine";
import prise from "./prise.js";
import Finalnew from "./finalnew.js";
import Fulltypewine from "./fulltypewine.js";
import axios from "axios";
import av from "./avatar/av.webp";
import Design from "./design.js";
import React, { useState, useEffect } from "react";
import Prise from "./prise.js";
import Region from "./region.js";
import SetDesign from "./setdesign";
import NewDBTest from "./newdbtest";
function App() {
    const [logo, setlogo] = useState("");
    const [avatar, setAv] = useState(av);
    const [data, setData] = useState([]);
    // const [foodtype, setfoodtype] = useState([]);
    const [step, setstep] = useState(0);
    const [palate, setpalate] = useState({
        colorr: "",
        season: "",
        taste: "",
        country: "",
        snack: "",
        pure: "",
        prise: "",
        region: "",
        laststep: 0,
    });

    // setpalate({ colorr: "black" });
    // useEffect(async () => {
    //     try {
    //         let foodtype = await axios.get("/foodlist");
    //         // setfoodtype(dataaa.data.data);
    //         console.log("food types :", foodtype.data.data);
    //     } catch (err) {
    //         console.log("axios error: ", err);
    //     }
    // }, []);
    // useEffect(async () => {
    //     if (step === 5) {
    //         try {
    //             let dataaa = await axios.post("/findwine", palate);
    //             setData(dataaa.data.data);
    //             console.log(data);
    //         } catch (err) {
    //             console.log("axios error: ", err);
    //         }
    //     }
    // }, [step]);
    let newload = true;
    useEffect(() => {
        if (newload) {
            SetDesign(setlogo);
            newload = false;
        }
    }, []);
    console.log(step);
    const backfunc = () => {
        setstep(palate.laststep);
    };
    return (
        <div className="App">
            <HashRouter>
                <div>
                    <div className="logoholder">
                        <img src={logo} className="logo" />
                    </div>
                    <div
                        className="loader point"
                        onClick={() => {
                            window.location.reload(false);
                        }}
                    >
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                        <div>
                            <div className="wineglass left">
                                <div className="top"></div>
                            </div>
                            <div className="wineglass right">
                                <div className="top"></div>
                            </div>
                        </div>
                    </div>
                    {step > 0 && (
                        <img
                            src="https://i.imgur.com/Fq5WzLX.png"
                            className="backbtn point"
                            onClick={backfunc}
                        />
                    )}
                    {/* <div onClick={send} className="testdb">
                    test the bd{" "}
                </div> */}

                    <Avatarmain avatar={avatar} />

                    <Avatar stepp={setstep} ava={setAv} steped={step} />
                    {/* <Colorr
                        steped={step}
                        pla={setpalate}
                        stepp={setstep}
                        pala={palate}
                    /> */}
                    <FirstQ
                        steped={step}
                        pla={setpalate}
                        stepp={setstep}
                        pala={palate}
                    />
                    <FoodType
                        steped={step}
                        pla={setpalate}
                        stepp={setstep}
                        snack={true}
                        pala={palate}
                    />
                    <MatchWine
                        steped={step}
                        pla={setpalate}
                        stepp={setstep}
                        snack={true}
                        pala={palate}
                    />
                    <Prise
                        steped={step}
                        pla={setpalate}
                        stepp={setstep}
                        snack={true}
                        pala={palate}
                    />
                    <Finalnew
                        steped={step}
                        pla={setpalate}
                        stepp={setstep}
                        snack={true}
                        pala={palate}
                    />

                    <Fulltypewine
                        steped={step}
                        pla={setpalate}
                        stepp={setstep}
                        snack={true}
                        pala={palate}
                    />
                    <Region
                        steped={step}
                        pla={setpalate}
                        stepp={setstep}
                        snack={true}
                        pala={palate}
                    />

                    {/* <Season
                        steped={step}
                        pla={setpalate}
                        stepp={setstep}
                        pala={palate}
                    />
                    <Taste
                        steped={step}
                        pla={setpalate}
                        stepp={setstep}
                        pala={palate}
                    />
                    <Country
                        steped={step}
                        pla={setpalate}
                        stepp={setstep}
                        pala={palate}
                    />
                    <Final
                        steped={step}
                        pla={setpalate}
                        stepp={setstep}
                        pala={palate}
                        data={data}
                    /> */}
                </div>

                <Route component={Createcountry} path="/up"></Route>
                <Route component={CreateFoodP} path="/upfood"></Route>
                <Route component={CreateWineType} path="/uptype"></Route>
                <Route component={CreateWine} path="/upwine"></Route>
                <Route component={NewDBTest} path="/db"></Route>
                <Route
                    path="/design"
                    render={() => <Design logo={setlogo} />}
                ></Route>
            </HashRouter>
        </div>
    );
}

export default App;
