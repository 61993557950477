import React, { useState, useEffect } from "react";
import whitewine from "./avatar/wwine.webp";
import redwine from "./avatar/redwine.png";
import rose from "./avatar/rose.png";
import axios from "axios";
const FoodType = (props) => {
    const [foodtype, setfoodtype] = useState([]);
    console.log("props  :", props.pala.snack);
    useEffect(async () => {
        if (props.steped === 2) {
            try {
                let foodtype = await axios.get(
                    `/foodlistbytype/${props.pala.snack}`
                );

                setfoodtype(foodtype.data.data);
                console.log("food types :", foodtype.data.data);
            } catch (err) {
                console.log("axios error: ", err);
            }
        }
    }, [props.steped]);
    return (
        props.steped === 2 &&
        props.pala.pure === false && (
            <div className="comp">
                {props.pala.snack == true && (
                    <h2>
                        What is/are the certain snack(s) you will be having your
                        wine with?
                    </h2>
                )}
                {props.pala.snack == false && (
                    <h2>What food will you be having your wine with?</h2>
                )}
                {/* <img
                    src={redwine}
                    alt="Red Wine"
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "red" });
                        props.stepp(2);
                    }}
                />
                <img
                    src={whitewine}
                    alt="White Wine "
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "white" });
                        props.stepp(2);
                    }}
                />
                <img
                    src={rose}
                    alt="rose Wine "
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "rose" });
                        props.stepp(2);
                    }}
                /> */}
                <div className="foodtypegrid">
                    {foodtype.length > 0 &&
                        foodtype.map((x, idx) => {
                            return (
                                x.tast_pro != "Preparation" && (
                                    <div
                                        className="basiclist foodtypelist"
                                        key={idx}
                                    >
                                        {/* <h3> ID : {x.id} </h3> */}
                                        <img
                                            src={x.tast_img}
                                            onClick={() => {
                                                props.pla({
                                                    ...props.pala,
                                                    taste: x.id,
                                                    laststep: 2,
                                                });
                                                props.stepp(3);
                                            }}
                                        />
                                        <div className="emptydiv3"></div>
                                        <h5
                                            onClick={() => {
                                                props.pla({
                                                    ...props.pala,
                                                    taste: x.id,
                                                    laststep: 2,
                                                });
                                                props.stepp(3);
                                            }}
                                        >
                                            {" "}
                                            {x.main_food}{" "}
                                        </h5>
                                        <div className="emptydiv3"></div>
                                    </div>
                                )
                            );
                        })}
                </div>
                <div className="foodtypegrid">
                    {foodtype.length > 0 && !props.pala.snack && (
                        <div className="fullwidth">
                            <h1>Preparation</h1>{" "}
                        </div>
                    )}
                    {foodtype.length > 0 &&
                        !props.pala.snack &&
                        foodtype.map((x, idx) => {
                            return (
                                x.tast_pro == "Preparation" && (
                                    <div
                                        className="basiclist foodtypelist"
                                        key={idx}
                                    >
                                        <img
                                            src={x.tast_img}
                                            onClick={() => {
                                                props.pla({
                                                    ...props.pala,
                                                    taste: x.id,
                                                    laststep: 2,
                                                });
                                                props.stepp(3);
                                            }}
                                        />
                                        <div className="emptydiv3"></div>
                                        <h5
                                            onClick={() => {
                                                props.pla({
                                                    ...props.pala,
                                                    taste: x.id,
                                                    laststep: 2,
                                                });
                                                props.stepp(3);
                                            }}
                                        >
                                            {" "}
                                            {x.main_food}{" "}
                                        </h5>
                                        <div className="emptydiv3"></div>
                                    </div>
                                )
                            );
                        })}
                </div>
            </div>
        )
    );
};

export default FoodType;
