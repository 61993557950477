import axios from "axios";
import React, { useEffect, useState } from "react";
import useStatefulFields from "./hooks/setfild";
import SetDesign from "./setdesign";
const Design = (props) => {
    const [values, handleChange] = useStatefulFields();

    const [flexgrow, setflexgrow] = useState();
    const [backdesign, setbackdesign] = useState([]);
    const [h5size, seth5size] = useState();
    let root = document.documentElement;
    let t = true;
    useEffect(async () => {
        if (t) {
            let backdata = await axios.get("/designdata");
            console.log(backdata.data.rows[0]);
            setbackdesign(backdata.data.rows[0]);
            console.log("sef", backdesign.backcolor !== null);
            console.log("sef", backdesign.backcolor);
            t = false;
            // SetDesign();
        }
    }, []);
    useEffect(() => {
        root.style.setProperty("--backcolor", values.backcolor);
        root.style.setProperty("--bordercolor", values.bordercolor);
        root.style.setProperty("--bordercolor2", values.bordercolor2);
        root.style.setProperty("--textcolor", values.textcolor);
        root.style.setProperty("--borderwidth2", values.borderwidth2 + "px");
        root.style.setProperty("--borderwidth", values.borderwidth + "px");
        root.style.setProperty("--flexgrow", values.flexgrow + "px");
        root.style.setProperty("--h2size", values.h2size + "px");
        root.style.setProperty("--h3size", values.h3size + "px");
        root.style.setProperty("--h5size", values.h5size + "px");
        // root.style.setProperty("--backcolor", values.backcolor);
        root.style.setProperty("--bcurl", "url('" + values.bcurl + "')");
        root.style.setProperty(
            "--backdropblur",
            "blur(" + values.backdropblur + "px)"
        );

        root.style.setProperty("--logosize", values.logosize + "px");
        root.style.setProperty("--fontfam", values.fontfam);
        root.style.setProperty("--backgroundatt", values.backgroundatt);
        props.logo(values.logo);
    }, [values]);

    const submit = async () => {
        let data = await axios.post("/designdata", {
            ...backdesign,
            ...values,
        });
        console.log("dataaaa", data);
    };
    return (
        backdesign !== [] && (
            <div>
                <h2>Design your Page : </h2>
                <div>
                    <div className="emptydiv"></div>
                    <div className="row desrows">
                        <div>
                            <div>
                                <h3>set LOGO</h3>

                                <input
                                    type="url"
                                    name="logo"
                                    defaultValue={backdesign.logo}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <h3>change the logo size </h3>

                                <input
                                    type="range"
                                    min="100"
                                    max="500"
                                    step="25"
                                    name="logosize"
                                    Value={backdesign.logosize}
                                    onChange={handleChange}
                                />
                                <h4> {values.logosize} </h4>
                            </div>

                            <div>
                                <h3>set Background</h3>

                                <input
                                    type="url"
                                    name="bcurl"
                                    defaultValue={backdesign.bcurl}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <h3>background blur </h3>

                                <input
                                    type="range"
                                    min="1"
                                    max="30"
                                    step="1"
                                    name="backdropblur"
                                    Value={backdesign.backdropblur}
                                    onChange={handleChange}
                                />
                                <h4> {values.backdropblur} </h4>
                            </div>
                            <div>
                                <h3> backgroung attachment </h3>
                                <select
                                    name="backgroundatt"
                                    onChange={handleChange}
                                    defaultValue={backdesign.backgroundatt}
                                    // value="scroll"
                                >
                                    <option value="fixed">fixed</option>
                                    <option value="scroll">scroll</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            {" "}
                            <div>
                                <h3>change the Background colors </h3>
                                <input
                                    type="color"
                                    name="backcolor"
                                    Value={backdesign.backcolor}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <h3>change the Text colors </h3>

                                <input
                                    type="color"
                                    name="textcolor"
                                    Value={backdesign.textcolor}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <h3>change the border colors </h3>

                                <input
                                    type="color"
                                    name="bordercolor"
                                    Value={backdesign.bordercolor}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <h3>change the border width 1 </h3>

                                <input
                                    type="range"
                                    min="0"
                                    max="20"
                                    step="1"
                                    name="borderwidth"
                                    Value={backdesign.borderwidth}
                                    onChange={handleChange}
                                />
                                {/* <h4> {flexgrow} </h4> */}
                            </div>
                            <div>
                                <h3>change the border width 2 </h3>

                                <input
                                    type="range"
                                    min="1"
                                    max="20"
                                    step="1"
                                    name="borderwidth2"
                                    Value={backdesign.borderwidth2}
                                    onChange={handleChange}
                                />
                                <h4> {flexgrow} </h4>
                            </div>
                            <div>
                                <h3>Multiple-choice card distance </h3>

                                <input
                                    type="range"
                                    min="100"
                                    max="700"
                                    step="25"
                                    name="flexgrow"
                                    Value={backdesign.flexgrow}
                                    onChange={handleChange}
                                />
                                <h4> {flexgrow} </h4>
                            </div>
                        </div>

                        <div>
                            <div>
                                <h3>change the fontsize h2</h3>

                                <input
                                    type="range"
                                    min="30"
                                    max="100"
                                    step="10"
                                    name="h2size"
                                    Value={backdesign.h2size}
                                    onChange={handleChange}
                                />
                                <h4> {values.h2size} </h4>
                            </div>
                            <div>
                                <h3>change the fontsize h3</h3>

                                <input
                                    type="range"
                                    min="10"
                                    max="30"
                                    step="2"
                                    name="h3size"
                                    Value={backdesign.h3size}
                                    onChange={handleChange}
                                />
                                <h4> {values.h3size} </h4>
                            </div>
                            <div>
                                <h3>change the fontsize h5</h3>

                                <input
                                    type="range"
                                    min="10"
                                    max="20"
                                    step="1"
                                    name="h5size"
                                    Value={backdesign.h5size}
                                    onChange={handleChange}
                                />
                                <h4> {values.h5size} </h4>
                            </div>
                            <div>
                                <h3>change font</h3>
                                <select
                                    name="fontfam"
                                    onChange={handleChange}
                                    selected={backdesign.fontfam}
                                >
                                    <option value="Major Mono Display">
                                        Google : Major Mono Display
                                    </option>
                                    <option value="Bungee Shade">
                                        Google : Bungee Shade
                                    </option>
                                    <option value="Cutive Mono">
                                        Google : Cutive Mono
                                    </option>
                                    <option value="Dancing Script">
                                        Google : Dancing Script
                                    </option>
                                    <option value="Lobster Two">
                                        Google : Lobster Two
                                    </option>
                                    <option value="Monoton">
                                        Google : Monoton
                                    </option>
                                    <option value="Orbitron">
                                        Google : Orbitron
                                    </option>
                                    <option value="Press Start 2P">
                                        Google : Press Start 2P
                                    </option>
                                    <option value="Roboto">
                                        Google : Roboto
                                    </option>
                                    <option value="Six Caps">
                                        Google : Six Caps
                                    </option>
                                    <option value="Staatliches">
                                        Google : Staatliches
                                    </option>
                                    <option value="Courier New">
                                        Courier New
                                    </option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Verdana">Verdana</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h3>Password </h3>

                        <input
                            type="password"
                            name="pass"
                            onChange={handleChange}
                        />
                    </div>

                    <button onClick={submit}>submit</button>
                    <div className="emptydiv"></div>
                </div>
            </div>
        )
    );
};

export default Design;
