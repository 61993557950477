import "./createcountry.css";
import React, { useEffect, useState } from "react";
import useStatefulFields from "./hooks/setfild";
import axios from "axios";
import Edit from "./edit.js";

const Createcountry = () => {
    const [values, handleChange] = useStatefulFields();
    const [clist, setClist] = useState({});
    const [toedit, setToEdit] = useState([]);
    const [idval, setIdval] = useState(1);
    const [portal, setportal] = useState(false);
    const [back, setBack] = useState({});
    useEffect(async () => {
        const { data } = await axios.get(`/countrylist`);
        setClist(data.data);
        console.log(data.data);

        if (data) {
            let le = data.data.length;

            setIdval(data.data[le - 1].id + 1);
        }
    }, []);
    useEffect(async () => {
        console.log("this back ", back);
        const { data } = await axios.post(`/countrylistup`, { ...back });
        console.log("back from db after updated ", data);
    }, [back]);

    const clicked = async (e) => {
        e.preventDefault();
        console.log(values);
        const { data } = await axios.post(`/countrylist`, {
            ...values,
            id: idval,
        });

        setClist([...clist, data.data[0]]);
        console.log("add test", data.data[0]);
        console.log("clist ", clist);
    };
    const editcountry = async (countryId) => {
        await setportal(false);
        console.log("country ID ", countryId);
        setToEdit(clist[countryId]);
        setportal(true);
    };
    const deletcountry = async (countryId) => {
        await setportal(false);
        console.log("country ID ", countryId);
        const { data } = await axios.post(`/countrylistdel`, { countryId });
        setToEdit(clist[countryId - 1]);
        setportal(true);
    };

    return (
        <div>
            <form className="ccountry_form">
                <h3>Adding a new country</h3>
                <input
                    name="id"
                    placehold="ID"
                    type="number"
                    onChange={handleChange}
                    disabled
                    value={idval}
                />
                <input
                    name="country"
                    placehold="Country"
                    onChange={handleChange}
                />
                <input
                    name="img"
                    placehold="Image URL"
                    type="url"
                    onChange={handleChange}
                />

                <h3>Password </h3>

                <input type="password" name="pass" onChange={handleChange} />

                <button onClick={(e) => clicked(e)}>Create</button>
            </form>

            <div className="edititem">
                {clist.length > 0 &&
                    clist.map((x, idx) => {
                        return (
                            <div className="basiclist" key={idx}>
                                <h3> ID : {x.id} </h3>
                                <h3> Country : {x.country} </h3>
                                <h3> IMG : {x.img} </h3>
                                <button onClick={() => editcountry(idx)}>
                                    Edit
                                </button>
                                {/* <button onClick={() => deletcountry(x.id)}>
                                    Delete
                                </button> */}
                            </div>
                        );
                    })}
            </div>
            {portal && <Edit obj={toedit} back={setBack} portal={setportal} />}
        </div>
    );
};

export default Createcountry;
