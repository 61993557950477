import React from "react";
import av1 from "./avatar/001.webp";
import av2 from "./avatar/002.webp";
import av3 from "./avatar/003.webp";
import av4 from "./avatar/004.webp";
import av5 from "./avatar/005.webp";
import av6 from "./avatar/006.webp";
const Avatar = (props) => {
    console.log(props);
    // props.stepp(2);
    return (
        props.steped === 0 && (
            <div className="comp">
                <h2>please pick an Avatar</h2>
                <img
                    className="point"
                    src={av1}
                    alt="avatar sample "
                    onClick={() => {
                        props.ava(av1);
                        props.stepp(1);
                    }}
                />
                <img
                    className="point"
                    src={av2}
                    alt="avatar sample "
                    onClick={() => {
                        props.ava(av2);
                        props.stepp(1);
                    }}
                />
                <img
                    className="point"
                    src={av3}
                    alt="avatar sample "
                    onClick={() => {
                        props.ava(av3);
                        props.stepp(1);
                    }}
                />
                <img
                    className="point"
                    src={av4}
                    alt="avatar sample "
                    onClick={() => {
                        props.ava(av4);
                        props.stepp(1);
                    }}
                />
                <img
                    className="point"
                    src={av5}
                    alt="avatar sample "
                    onClick={() => {
                        props.ava(av5);
                        props.stepp(1);
                    }}
                />
                <img
                    className="point"
                    src={av6}
                    alt="avatar sample "
                    onClick={() => {
                        props.ava(av6);
                        props.stepp(1);
                    }}
                />
            </div>
        )
    );
};

export default Avatar;
