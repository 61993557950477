import "./createcountry.css";
import React, { useEffect, useState } from "react";
import useStatefulFields from "./hooks/setfild";
import axios from "axios";
import Edit from "./edit.js";

const CreateWineType = () => {
    const [values, handleChange] = useStatefulFields();
    const [clist, setClist] = useState({});
    const [toedit, setToEdit] = useState([]);
    const [idval, setIdval] = useState(1);
    const [portal, setportal] = useState(false);
    const [back, setBack] = useState({});
    useEffect(async () => {
        const { data } = await axios.get(`/typelist`);
        setClist(data.data);
        console.log(data.data);

        if (data) {
            let le = data.data.length;

            setIdval(data.data[le - 1].id + 1);
        }
    }, []);
    useEffect(async () => {
        console.log("this back ", back);
        const { data } = await axios.post(`/typelistup`, { ...back });
        console.log("back from db after updated ", data);
    }, [back]);

    const clicked = async (e) => {
        e.preventDefault();
        console.log(values);
        const { data } = await axios.post(`/typelist`, {
            ...values,
            id: idval,
        });

        setClist([...clist, data.data[0]]);
        console.log("add test", data.data[0]);
        console.log("clist ", clist);
    };
    const editcountry = async (countryId) => {
        await setportal(false);
        console.log("country ID ", countryId);
        setToEdit(clist[countryId]);
        setportal(true);
    };
    const deletfood = async (typeId) => {
        await setportal(false);
        console.log("type ID ", typeId);
        const { data } = await axios.post(`/typelistdel`, { typeId });
        setToEdit(clist[typeId - 1]);
        // setportal(true);
    };

    return (
        <div>
            <form className="ccountry_form">
                <h3>Adding a new country</h3>
                <input
                    name="id"
                    placehold="ID"
                    type="number"
                    onChange={handleChange}
                    disabled
                    value={idval}
                />
                <input
                    name="type_name"
                    placehold="type name"
                    onChange={handleChange}
                />
                <input
                    name="food_pair"
                    placehold="food pair"
                    onChange={handleChange}
                />
                <input
                    name="pair_to_food_pwr"
                    placehold="pair to food Power"
                    onChange={handleChange}
                />
                <input name="color" placehold="color" onChange={handleChange} />
                <input
                    name="commercial_type "
                    placehold="commercial type "
                    onChange={handleChange}
                />
                <input
                    name="img"
                    placehold="img URL "
                    type="url"
                    onChange={handleChange}
                />
                <h3>Password </h3>
                <input type="password" name="pass" onChange={handleChange} />
                wine
                <button onClick={(e) => clicked(e)}>Create</button>
            </form>

            <div className="edititem">
                {clist.length > 0 &&
                    clist.map((x, idx) => {
                        return (
                            <div className="basiclist" key={idx}>
                                <h3> ID : {x.id} </h3>
                                <h3> type name : {x.type_name} </h3>
                                <h3> food pair : {x.food_pair} </h3>
                                <h3>pair to food pwr : {x.pair_to_food_pwr}</h3>
                                <h3> color : {x.color} </h3>
                                <h3> commercial type : {x.commercial_type} </h3>
                                <h3> img : {x.img} </h3>
                                <button onClick={() => editcountry(idx)}>
                                    Edit
                                </button>
                                {/* <button onClick={() => deletfood(x.id)}>
                                    Delete
                                </button> */}
                            </div>
                        );
                    })}
            </div>
            {portal && <Edit obj={toedit} back={setBack} portal={setportal} />}
        </div>
    );
};

export default CreateWineType;
