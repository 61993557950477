import axios from "axios";

const SetDesign = async (setdesign) => {
    let { data } = await axios.get("/designdata");
    // console.log("design", data.rows[0]);
    let values = data.rows[0];
    let root = document.documentElement;
    root.style.setProperty("--backcolor", values.backcolor);
    root.style.setProperty("--bordercolor", values.bordercolor);
    root.style.setProperty("--bordercolor2", values.bordercolor2);
    root.style.setProperty("--textcolor", values.textcolor);
    root.style.setProperty("--borderwidth2", values.borderwidth2 + "px");
    root.style.setProperty("--borderwidth", values.borderwidth + "px");
    root.style.setProperty("--flexgrow", values.flexgrow + "px");
    root.style.setProperty("--h2size", values.h2size + "px");
    root.style.setProperty("--h3size", values.h3size + "px");
    root.style.setProperty("--h5size", values.h5size + "px");
    // root.style.setProperty("--backcolor", values.backcolor);
    root.style.setProperty("--bcurl", "url('" + values.bcurl + "')");
    root.style.setProperty(
        "--backdropblur",
        "blur(" + values.backdropblur + "px)"
    );

    root.style.setProperty("--logosize", values.logosize + "px");
    root.style.setProperty("--fontfam", values.fontfam);
    root.style.setProperty("--backgroundatt", values.backgroundatt);

    setdesign(values.logo);

    return true;
};

export default SetDesign;
