import React from "react";

const Prise = (props) => {
    return (
        props.steped === 4 && (
            <div className="comp colmob">
                <h2>
                    What is the price range for the wine you’re looking for?{" "}
                </h2>
                {/* <img
                    src={redwine}
                    alt="Red Wine"
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "red" });
                        props.stepp(2);
                    }}
                />
                <img
                    src={whitewine}
                    alt="White Wine "
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "white" });
                        props.stepp(2);
                    }}
                />
                <img
                    src={rose}
                    alt="rose Wine "
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "rose" });
                        props.stepp(2);
                    }}
                /> */}

                <div className="prise">
                    <img
                        className="point"
                        src="https://i.imgur.com/WvuRMRd.png"
                        onClick={() => {
                            props.pla({
                                ...props.pala,
                                prise: "down",
                                laststep: 4,
                            });
                            props.stepp(5);
                        }}
                    />
                    <div className="emptydiv2"></div>
                    <h2
                        className="point"
                        onClick={() => {
                            props.pla({
                                ...props.pala,
                                prise: "down",
                                laststep: 4,
                            });
                            props.stepp(5);
                        }}
                    >
                        less than 10 euro
                    </h2>
                </div>
                <div className="prise">
                    <img
                        className="point"
                        src="https://i.imgur.com/ntJDEOQ.png"
                        onClick={() => {
                            props.pla({
                                ...props.pala,
                                prise: "up",
                                laststep: 4,
                            });
                            props.stepp(5);
                        }}
                    />
                    <div className="emptydiv2"></div>
                    <h2
                        className="point"
                        onClick={() => {
                            props.pla({
                                ...props.pala,
                                prise: "up",
                                laststep: 4,
                            });
                            props.stepp(5);
                        }}
                    >
                        less then 50 euro
                    </h2>
                </div>

                <div className="prise">
                    <img
                        className="point"
                        src="https://i.imgur.com/nOUV9Ev.png"
                        onClick={() => {
                            props.pla({
                                ...props.pala,
                                prise: "unlim",
                                laststep: 4,
                            });
                            props.stepp(5);
                        }}
                    />
                    <div className="emptydiv2"></div>
                    <h2
                        className="point"
                        onClick={() => {
                            props.pla({
                                ...props.pala,
                                prise: "unlim",
                                laststep: 4,
                            });
                            props.stepp(5);
                        }}
                    >
                        no limit
                    </h2>
                </div>
            </div>
        )
    );
};

export default Prise;
