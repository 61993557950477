import React, { useState, useEffect } from "react";

import axios from "axios";

const Fulltypewine = (props) => {
    const [winetypefull, setWinetypefull] = useState([]);

    useEffect(async () => {
        console.log("prue");
        if (props.steped === 10) {
            try {
                let wineT = await axios.get(`/typelist`);

                setWinetypefull(wineT.data.data);

                console.log("food types :", wineT.data.data);
            } catch (err) {
                console.log("axios error: ", err);
            }
        }
    }, [props.steped]);
    return (
        props.steped === 10 && (
            <div className="comp">
                <h2> What is your preference regarding the wine type?</h2>
                {/* <img
                    src={redwine}
                    alt="Red Wine"
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "red" });
                        props.stepp(2);
                    }}
                />
                <img
                    src={whitewine}
                    alt="White Wine "
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "white" });
                        props.stepp(2);
                    }}
                />
                <img
                    src={rose}
                    alt="rose Wine "
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "rose" });
                        props.stepp(2);
                    }}
                /> */}
                <div className="row">
                    {winetypefull.length > 0 &&
                        winetypefull.map((x, idx) => {
                            return (
                                <div className="basiclist" key={idx}>
                                    {/* <h3> ID : {x.id} </h3> */}
                                    <img 
                                        className="biggerpicmob"
                                        src={x.img}
                                        onClick={() => {
                                            props.pla({
                                                ...props.pala,
                                                colorr: x.id,
                                                laststep: 10,
                                            });
                                            props.stepp(11);
                                        }}
                                    />
                                    <h3
                                        className="typetext"
                                        onClick={() => {
                                            props.pla({
                                                ...props.pala,
                                                colorr: x.id,
                                                laststep: 10,
                                            });
                                            props.stepp(11);
                                        }}
                                    >
                                        {" "}
                                        {x.type_name}{" "}
                                    </h3>
                                </div>
                            );
                        })}
                </div>
            </div>
        )
    );
};

export default Fulltypewine;
