// import france from "./avatar/france.png";
// import italy from "./avatar/italy.png";
// import spain from "./avatar/spain.png";
// import greece from "./avatar/greece.png";
// import aus from "./avatar/aus.png";
// import usa from "./avatar/usa.png";
import axios from "axios";
import list from "./list.json";
import React, { useState, useEffect } from "react";

const Finalnew = (props) => {
    const [data, setdata] = useState([]);
    const [cityl, setcityl] = useState([]);
    const [foodl, setfoodl] = useState([]);
    const [winetype, setwintype] = useState([]);
    useEffect(async () => {
        // console.log("data : ", "nnnnnooooo", props.steped);
        if (props.steped === 5) {
            console.log("final is ready");
            try {
                let dataaa = await axios.post("/findwinenew", props.pala);
                setdata(dataaa.data.data);
                console.log(dataaa.data.data);
                let city = await axios.get("/citylist");
                setcityl(city.data.data);
                console.log("city", city.data.data);
                let food = await axios.get("/foodlist");
                let typee = food.data.data;
                let typeo = [];
                for (let y in typee) {
                    typeo[typee[y].id] = typee[y];
                }
                setfoodl(typeo);

                console.log("food", typeo);
                let type = await axios.get("/typelist", props.pala);

                setwintype(type.data.data);
                console.log("type", type.data.data);
            } catch (err) {
                console.log("axios error: ", err);
            }
        }
    }, [props.steped]);

    useEffect(() => {
        console.log(data.length, winetype.length, cityl.length);
    });

    return (
        <div>
            {props.steped === 5 &&
                data.length > 0 &&
                winetype.length > 0 &&
                cityl.length > 0 && (
                    <div className="comp">
                        <div className="itemcount">
                            <h4> {data.length} items matched</h4>
                            <img src="https://i.imgur.com/m1vBj6t.png" />
                            {/* <h2> -------------------------------</h2> */}
                        </div>

                        {data.map((x, idx) => {
                            // cons/ole.log(idx, " : ", x);

                            return (
                                <div key={idx} className="finalr">
                                    <div className="emptydiv"></div>
                                    <div className="imgfinalbox">
                                        <img
                                            src={x.img}
                                            alt=""
                                            className="imginfinal"
                                        />
                                    </div>
                                    <br />
                                    <br />
                                    <div className="emptydiv2"></div>
                                    <h2>
                                        {idx + 1}. {x.wine_name}
                                    </h2>

                                    <h2>price from: {x.price}€</h2>
                                    {x.years != null && (
                                        <h2>year: {x.years}</h2>
                                    )}
                                    <h2>Region: {cityl[x.city - 1].city}</h2>
                                    <h3 className="border">{x.dis}</h3>
                                    <div className="emptydiv2"></div>
                                    <h3>Other suitable Food-Pairings: </h3>
                                    <div className="emptydiv2"></div>

                                    {/* <h2>food pairing: {x.wine_type}</h2> */}
                                    {winetype[x.wine_type - 1].food_pair.map(
                                        (h, idxx) => {
                                            return (
                                                <div key={idxx}>
                                                    <h4 className="foodpairlistt">
                                                        {foodl[h].main_food}
                                                    </h4>
                                                    {/* <h4>{h}</h4> */}
                                                </div>
                                            );
                                        }
                                    )}
                                    <br />
                                    <br />
                                    <br />
                                    <div className="emptydiv"></div>
                                    {/* <div className="hrc"></div> */}
                                    <hr />
                                    <br />

                                    <br />
                                </div>
                            );
                        })}

                        {/* <img src={product.pic} alt="" />
                <h1>{product.name}</h1>
                <h1>price: {product.price}</h1>
                <h2>year: {product.year}</h2>
                <h2>Country: {product.country}</h2>
                <h3>rate: {product.rate}</h3>
                <h3>{product.dis}</h3> */}
                    </div>
                )}
            {props.steped === 5 && data.length === 0 && (
                <div>
                    <div className="emptydiv"></div>
                    <div className="emptydiv"></div>
                    <div className="emptydiv"></div>
                    <div className="emptydiv"></div>
                    <h1>NO RESULTS</h1>
                </div>
            )}
        </div>
    );
};

export default Finalnew;
