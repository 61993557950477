import React, { useEffect, useState } from "react";
import useStatefulFields from "./hooks/setfild";
const Edit = (props) => {
    const [newdata, setnewdata] = useState([]);
    const [values, handleChange] = useStatefulFields();
    // console.log("edit page ", props.obj);

    var newyhing = [];
    useEffect(() => {
        console.log("edit page two", props);
        if (props.obj) {
            for (let key in props.obj) {
                console.log("key", key);
                console.log("text", props.obj[key]);

                newyhing.push([key, props.obj[key]]);
            }

            console.log("new thing ", newyhing);
            setnewdata(newyhing);
        }
    }, [props.obj]);
    const submits = () => {
        let updatemainvar = props.obj;
        // console.log("updated var oldd ", updatemainvar);
        for (let key in values) {
            updatemainvar[key] = values[key];
        }
        // console.log("updated var new ", updatemainvar);

        props.back(updatemainvar);
        props.portal(false);
    };

    return (
        <div className="mainedit">
            <div>
                EDIT
                <div>
                    {newdata.length > 0 &&
                        newdata.map((x, idx) => {
                            return (
                                <div key={idx}>
                                    <h3>{x[0]}</h3>{" "}
                                    <input
                                        name={x[0]}
                                        defaultValue={x[1]}
                                        onChange={handleChange}
                                    />
                                </div>
                            );
                        })}
                    <div>
                        <h3>Password </h3>

                        <input
                            type="password"
                            name="pass"
                            onChange={handleChange}
                        />
                    </div>
                    <button onClick={() => submits()}>Submit</button>
                </div>
            </div>
        </div>
    );
};

export default Edit;
