import React, { useState, useEffect } from "react";

import axios from "axios";

const MatchWine = (props) => {
    const [winetype, setwinetype] = useState([]);
    const [winetypeo, setwinetypeo] = useState([]);
    console.log("props  :", props.pala.snack);
    let winess;
    let wineorder = [];
    useEffect(async () => {
        if (props.steped === 3) {
            try {
                let wineT = await axios.get(
                    `/winetypebyfood/${props.pala.taste}`
                );
                winess = wineT.data.data;
                for (let i in winess) {
                    for (let k in winess[i].food_pair) {
                        if (winess[i].food_pair[k] === props.pala.taste) {
                            console.log(winess[i].type_name, i, k);
                            if (winess[i].pair_to_food_pwr[k] === 2) {
                                console.log(winess[i].type_name, "powerfull");
                                wineorder.unshift(winess[i]);
                            } else {
                                wineorder.push(winess[i]);
                            }
                        }
                    }
                }
                console.log("in orther", wineorder);
                setwinetype(wineT.data.data);
                setwinetypeo(wineorder);
                console.log("food types :", wineT.data.data);
            } catch (err) {
                console.log("axios error: ", err);
            }
        }
    }, [props.steped]);
    return (
        props.steped === 3 && (
            <div className="comp">
                {props.pala.snack == true && (
                    <h2>
                        What is your preference among the following different
                        wine types matching the snack?
                    </h2>
                )}
                {props.pala.snack == false && (
                    <h2>
                        What is your preference among the following different
                        wine types matching your food?
                    </h2>
                )}
                {/* <img
                    src={redwine}
                    alt="Red Wine"
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "red" });
                        props.stepp(2);
                    }}
                />
                <img
                    src={whitewine}
                    alt="White Wine "
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "white" });
                        props.stepp(2);
                    }}
                />
                <img
                    src={rose}
                    alt="rose Wine "
                    onClick={() => {
                        props.pla({ ...props.pala, colorr: "rose" });
                        props.stepp(2);
                    }}
                /> */}

                {/* {winetype.length > 0 &&
                    winetype.map((x, idx) => {
                        return (
                            <div className="basiclist" key={idx}>
                                <h3> ID : {x.id} </h3>
                                <img
                                    src={x.img}
                                    onClick={() => {
                                        props.pla({
                                            ...props.pala,
                                            colorr: x.id,
                                            laststep: 3,
                                        });
                                        props.stepp(4);
                                    }}
                                />
                                <h3
                                    onClick={() => {
                                        props.pla({
                                            ...props.pala,
                                            colorr: x.id,
                                            laststep: 3,
                                        });
                                        props.stepp(4);
                                    }}
                                >
                                    {" "}
                                    {x.type_name}{" "}
                                </h3>
                            </div>
                        );
                    })}
                <h1>-------------------</h1> */}
                <div className="row">
                    {winetypeo.length > 0 &&
                        winetypeo.map((x, idx) => {
                            return (
                                <div className="basiclist " key={idx}>
                                    {/* <h3> ID : {x.id} </h3> */}
                                    <img
                                        src={x.img}
                                        onClick={() => {
                                            props.pla({
                                                ...props.pala,
                                                colorr: x.id,
                                                laststep: 3,
                                            });
                                            props.stepp(4);
                                        }}
                                    />
                                    <h3
                                        className="typetext"
                                        onClick={() => {
                                            props.pla({
                                                ...props.pala,
                                                colorr: x.id,
                                                laststep: 3,
                                            });
                                            props.stepp(4);
                                        }}
                                    >
                                        {" "}
                                        {x.type_name}{" "}
                                    </h3>
                                </div>
                            );
                        })}
                </div>
            </div>
        )
    );
};

export default MatchWine;
