import React, { useEffect } from "react";
import axios from "axios";

const NewDBTest = () => {
    useEffect(async () => {
        const country = await axios.get(`/dbtest`);
        console.log("country", country);
    }, []);
    return <div></div>;
};

export default NewDBTest;
